/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, useEffect } from 'react';

import { connect } from 'react-redux';
import {
    TextInput
} from "@carbon/react";
import { ChevronRight } from '@carbon/react/icons';

import {
    RenderTurn,
    ConversationFilters
} from "./chatbot-components"
import {
    get, getArray, isEmptyObject, isMobileDevice, post_message
} from "../../utils";

import {
    getClickTargetText,
    USER_INPUT_PATH,
    USER_INPUT_KEY_PATH,
    TURNS_PATH,
    VISUAL_CUE_PATH
} from "./chatbot-utils"

import {
  makeChatbotInputActionThunk,
  makeUpdateUserInputAction,
  makeDeleteConversationFilter,
  makeUpdateIVLinkData,
  makeALLInDepthDrugDataResponseActionThunk_chatbot,
  makeALLQuickAnsResponseActionThunk_chatbot, makeDrugInteractionActionThunk
} from '../../app-state-redux'

import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {makeIVCompSetDrugsAndSolutionsAction} from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-compatibility-summary-page/iv-compatibility-summary-utils";
import {composeExternalNavigationMessage} from "./chatbot-components/RenderTurn";
import {
    getFilterObjectForDefinedDrugsAndAllergies,
    GLOBAL_DRUG_NAMES_STATE_PATH,
} from "../../utils/commonIvDiUtils";
import {
  makeApplyNewFilterAction,
  makeChangeInteractionObjectsAction,
  makeShowFilterPanelAction
} from "../DrugInteractionPage/drug-interaction-utils";
import {makeDiShowUnableToDeleteMessageAction} from "../DrugInteractionPage/drug-interaction-utils/drug-interaction-page-redux";
import {CHATBOT_PAGE_NAME, ACTIVE_STATUS} from "../../components/response-loading/redux/response-loading-redux";
import { makeSetReloginStateAction } from '../LogoutPage/logout-page-redux';
import { makeSaveSearchTermStateAction } from '../HomePage/home-page-utils/home-page-redux';

const ChatbotInputProps = {
    className: 'chatbotInput',
    id: 'chatbotUserInput',
    labelText: '',
    light: false,
    placeholder: "Type a drug information question..."
};

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
    return {
        conversation_turns: get(state.chatbot_page,TURNS_PATH),
        complete_user_input: get(state.chatbot_page,USER_INPUT_PATH),
        user_input_key: get(state.chatbot_page,USER_INPUT_KEY_PATH),
        drug_points_page_not_initialized: isEmptyObject(state.drug_points_page),
        visual_cue: get(state.chatbot_page, VISUAL_CUE_PATH, {}),
        return_target: get(state.chatbot_page, 'return_target', ''),
        subscription_state: get(state, 'login_page.mdxAuthenticationJson', {}),
        clientConfig: get(state.login_page, "clientConfiguration"),
        iv_drugs: get(state, GLOBAL_DRUG_NAMES_STATE_PATH, []),
        previous_iv_link_drugs: get(state,'chatbot_page.previous_iv_drugs'),
        credential : {username : get(state.login_page, 'username', ''),
        password : get(state.login_page, 'password','')},
        gateway_login_page : get(state, 'gateway_login_page'),  ////IFrame login and client config details
        chatbotPageResponseLoading: get(state,`inline_loading.${CHATBOT_PAGE_NAME}`,{}),
        isLoginProcessFinished: get(state.gateway_login_page, 'isLoginProcessFinished', false),
        shouldNotApplyFocusToChatbotInput: get(state.chatbot_page, "shouldNotApplyFocusToChatbotInput", false)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSendChatbotInput: (userInput, self) => {
            dispatch(makeChatbotInputActionThunk(userInput, self.props))
            self.setState({
                inputValue: ''
            });
        },
        onUpdateUserInput: temp_user_input => {
            dispatch(makeUpdateUserInputAction(temp_user_input))
        },
        onDeleteConversationFilter: (filter_name, props)=> {
             // update UI by immediately removing named filter and temporarily disabling clicks
             dispatch(makeDeleteConversationFilter(filter_name))
             // send a clear filter_name message to chatbot service ...
             dispatch(makeChatbotInputActionThunk(`Clear ${filter_name}`, props))
        },
        onSetReloginState: () => {
            dispatch(makeSetReloginStateAction(false));
        },

        onIVUpdate: (iv_link_data,  props) => {

            const drugIds=getArray(iv_link_data,'drugIds');
            let drug_names=[]

            for (var j = 0; j < drugIds.length; j++) {
                drug_names.push(drugIds[j].name);
            }
            if(drug_names.length!==0) {
                drug_names.sort();
            }
            console.debug("ChatbotPage::onIVUpdate", "drug_names=", drug_names)
            const drugs_from_props =get(props, 'iv_drugs');
            const previous_drugs = get(props,'previous_iv_link_drugs');
            console.debug(drugs_from_props);
            console.debug(previous_drugs);
            console.debug(drug_names);
            if((drug_names.length!==0&&(drugs_from_props === undefined || drugs_from_props.length===0))
                ||(drug_names.length!==0
                    &&(drugs_from_props.length === previous_drugs.length)
                    && (drugs_from_props.every(function(value, index) { return value === previous_drugs[index]}))
                    && ! (drug_names.every(function(value, index) { return value === drugs_from_props[index]}))
                )
            )
            {
                dispatch(makeIVCompSetDrugsAndSolutionsAction(
                    drug_names
                ))
                // const child_props = {
                //     agent_names: drug_names,
                //     result_types,
                //     selected_solutions: [],
                //     props
                // }
               // dispatch(makeIVCompatibilityThunk(child_props));
                dispatch(makeUpdateIVLinkData(drug_names))
            }},
        onInDepthLinkForm: (inDepthInfo, props) => {
            dispatch(makeALLInDepthDrugDataResponseActionThunk_chatbot(inDepthInfo, props))
        },
        onQuickAnsLinkForm: (quickAnsInfo, props) => {
             dispatch(makeALLQuickAnsResponseActionThunk_chatbot(quickAnsInfo, props))
        },
        onLaunchExternalNavigation: (externalNavMessage,secured_origins, event) => {  ///////////// URL CONFIG
            console.debug(secured_origins);
            console.debug(externalNavMessage)
            post_message(externalNavMessage, secured_origins)
            if(event!==undefined) {
                event.preventDefault(); // keep anchor link from visiting href url
            }
            return false;   // do not open anchor tag's HREF url
        },
        onDisplayDrugInteractionsWithDefaultState: (props, drugs) => {
          const drugIds = drugs.map(drug => drug.id);
          const drugInteractionsFilterObject = getFilterObjectForDefinedDrugsAndAllergies(drugIds, []);
          const drugInteractionsObject = { drugs: drugs, allergies: [] };
          dispatch(makeChangeInteractionObjectsAction(drugInteractionsObject));
          dispatch(makeApplyNewFilterAction(drugInteractionsFilterObject));
          dispatch(makeShowFilterPanelAction(false));
          dispatch(makeDrugInteractionActionThunk(drugIds, [], props));
          dispatch(makeDiShowUnableToDeleteMessageAction(false));
        },
        onClearSearchTerm: () => {
            dispatch(makeSaveSearchTermStateAction(''));
          }
    }
}

/**
 * To disable automatically loading the chatbot service's welcome page,
 * use this prop: disable_welcome_page=true
 */
export class ChatbotPagePresentation extends Component {

    inputFieldRef = null
    inputFieldKey = 1

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };

        this.updateInputValue = this.updateInputValue.bind(this)

        this.inputFieldRef = React.createRef()

        this.handleSubmit = this.handleSubmit.bind(this)

        this.onDrugForFindingLinkClick = this.onDrugForFindingLinkClick.bind(this)

        // set global value to be used by <sel> tag click handlers
        this.onSelectLinkClick = this.onSelectLinkClick.bind(this)
        window.pwa_onSelectLinkClick = this.onSelectLinkClick;
        window.pwa_linkOnClick = this.onDrugForFindingLinkClick;


        if (String(window.performance.getEntriesByType("navigation")[0].type) === "reload"
            && this.props.conversation_turns.length > 0) {
            const lastChatbotConversationTurnIndex
                = this.props.conversation_turns.length - 1
            const lastChatbotConversationTurn
                = this.props.conversation_turns[lastChatbotConversationTurnIndex];
            const lastChatbotUserInput = lastChatbotConversationTurn.user_input;
            const lastChatbotResponse = lastChatbotConversationTurn.chatbot_response;

            if (lastChatbotResponse === ".... waiting ... ") {
                this.props.conversation_turns.pop(lastChatbotConversationTurn)
                this.props.onSendChatbotInput(
                    lastChatbotUserInput,
                    this
                )
            }
        }
    }
    onDrugForFindingLinkClick(target, contentSetId, drugIdentifier, topicId, drugName,drugEvalsDocId) {
        console.debug('Coming here on post message###################:',drugEvalsDocId)
        const    quickLinkDocumentId=drugIdentifier;
        const    inDepthLinkDocumentId=drugEvalsDocId
        const topicList=topicId;
        //Add logic to fin external links from props
        const external_links= getArray(this.props.gateway_login_page,'mdxAuthenticationJson.Client_Config.externallinks');
        let secured_origins= get(this.props.gateway_login_page,'mdxAuthenticationJson.Client_Config.secured_origins');
        // secured_origins=JSON.stringify(secured_origins);
        // secured_origins=secured_origins.replace("[","");
        // secured_origins=secured_origins.replace("]","");
        console.debug('secured_origins$$$$$$$$$$$$: ', secured_origins);

        if(external_links!==undefined && external_links.filter(link => link.quickLinks)[0]){
            const params={
                contentSetId,
                drugName,
                quickLinkDocumentId,
                inDepthLinkDocumentId,
                topicList
            }
            console.debug('PARAMS$$$$$$$$$$$$: ', params);
            const post_message = composeExternalNavigationMessage(
                params
            )

           this.props.onLaunchExternalNavigation(post_message,secured_origins)
           //  window.parent.postMessage(post_message, secured_origins);
        } else {
            const unencodedSearch = '?contentSetId=' + contentSetId + '&drugName=' + drugName + '&documentId='
                + drugIdentifier + '&topicId=' + topicId + '&inDepthLinkDocumentId=' + inDepthLinkDocumentId;
            const encodedSearch = encodeURI(unencodedSearch);
            this.props.history.push({
                pathname: '/drugPoints',
                search: encodedSearch
            });
        }
    }

    /**
     * onClick handler for <sel> tags in chatbot response HTML
     *
     * NOTE: global window.pwa_onSelectLinkClick = this.onSelectLinkClick;
     * @param target - the onClick event
     */
    onSelectLinkClick(target){
        const isResponseLoading = get(this.props.chatbotPageResponseLoading, 'status' ,'') !== ACTIVE_STATUS;

        if (isResponseLoading) {
            this.props.onSendChatbotInput(
                getClickTargetText(target),
                this
            )
        }
    }

    /**
     * form onSubmit() triggered when the Enter key is hit inside of an input field
     * @param event
     */
    handleSubmit(event) {
        if(event !== undefined)
            event.preventDefault(); // keep page from redrawing itself
        if (this.inputFieldRef.current.value.trim()) {
            this.props.onSendChatbotInput(
                this.inputFieldRef.current.value,
               // this.props.complete_user_input
                this
            )
        }
        this.setState({
            inputValue: ''
        });
    }

    updateInputValue(evt) {
        this.setState({
            inputValue:evt.target.value
        });
    }

    /**
     * When the react gui is displayed, send the first chatbot service request
     * in order to display the chatbot Welcome page
     */
    componentDidMount() {
        if (this.isIE()) {
            this.resize();
        }

        if (this.inputFieldRef.current && !isMobileDevice() && !this.props.shouldNotApplyFocusToChatbotInput) {
            this.inputFieldRef.current.focus();
        }

        if (
            // only ask for welcome page on first start up
            this.props.conversation_turns.length===0
        ) {
            this.props.onSendChatbotInput('', this)
        }
        this.props.onSetReloginState();
    }

    componentDidUpdate() {
        if (this.isIE()) {
            this.resize();
        }
        if (this.inputFieldRef.current && !isMobileDevice() && !this.props.shouldNotApplyFocusToChatbotInput) {
            this.inputFieldRef.current.focus();
        }

        if (this.props.isLoginProcessFinished && this.props.conversation_turns.length === 0) {
            this.props.onSendChatbotInput('', this)
        }
    }

    updateIVState(iv_link_data) {
        this.props.onIVUpdate(
            iv_link_data,
            this.props
        )
    }

    isIE = () => {
        let agent = navigator.userAgent.toLowerCase();
        return (agent.indexOf("msie") !== -1 || agent.indexOf("trident") !== -1 || agent.indexOf(" edge/") !== -1);
    }

    resize = () => {
        const chatbotFiltersPadding = 96;
        const filterItemMargin = 5.7;
        const filterItemHeight = 34;
        let filterItems = document.getElementsByClassName("mdx-filter-item");
        let chatbotFilters = document.getElementById("chatbot-filters");
        let chatbotFiltersWidth = chatbotFilters.scrollWidth - chatbotFiltersPadding;
        let rowsCount = 0;
        let sum = 0;
        for (let i = 0; i < filterItems.length - 1; i++) {
            let itemWidth = filterItems[i].scrollWidth + filterItemMargin;
            if (sum + itemWidth > chatbotFiltersWidth) {
                rowsCount++;
                sum = itemWidth;
            } else {
                sum += itemWidth;
            }
        }
        if (sum > 0) {
            rowsCount++;
        }
        chatbotFilters.setAttribute("style", "min-height: " + filterItemHeight * rowsCount + "px");
    }

    componentWillUnmount() {
        if (this.isIE()) {
            window.removeEventListener("resize", this.resize);
        }
    }

    /**
     * Render the ChatbotPage
     * @returns {*}
     */
    render() {
        const visual_cue = this.props.visual_cue

        const enable_filters = get(visual_cue,'enable', true)
        const disable_class = enable_filters?'':' mdx-filter-disable'
        const filter_list = get(visual_cue,'list',[])
        const filter_count = filter_list.length
        const zero_filter_class = filter_count===0?' mdx-filter-count-zero':''
        console.debug(this.props.conversation_turns);
        const turns=this.props.conversation_turns;
        const isResponseLoading = get(this.props.chatbotPageResponseLoading, 'status' ,'') !== ACTIVE_STATUS;


        if(turns.length>1){
            const links = get(turns[turns.length-1],'links',{})
            const content_set_id=get(links[0], 'contentSetId','')
            if(content_set_id === '39') {
                // if no subscription, avoid pre-loading content
                var subscriptionInfo = this.props.subscription_state;
                const hasIVCompatibility = get(subscriptionInfo, 'IVCompatibilityYSiteAdmixtureSyringe', 'no');
                if (hasIVCompatibility === 'yes') {
   // TODO: causing loop !!!!!!!!!!!!!!!                 this.updateIVState(links[0]);
                }
            }
        }
        console.debug(this.props.conversation_turns);

        if (this.isIE()) {
            window.addEventListener("resize", this.resize);
        }
        return  (
            <div key='chatbotParent' className="chatbotParentContent">
                <div className={"chatbotParent mdx-chatbot-parent-div"}>
                    <div id="chatbot-filters"
                         className={"mdx-chatbot-filter-row"+disable_class+zero_filter_class}>
                        <ConversationFilters
                            {...visual_cue}
                            props={this.props}
                            onSendChatbotInput={this.props.onSendChatbotInput}
                            onDeleteConversationFilter={this.props.onDeleteConversationFilter}
                            isChatBotActionEnabled={isResponseLoading}
                            chatBotContext={this}
                        />
                    </div>

                    <div id="mdx-chatbot-turns" className="mdx-chatbot-turns-row">
                        <ScrollWindow
                            conversation_turns={this.props.conversation_turns}
                            subscriptionInfo={this.props.subscription_state}
                            clientConfig={this.props.clientConfig}
                            credential={this.props.credential}
                            onInDepthLinkForm={this.props.onInDepthLinkForm}
                            onQuickAnsLinkForm={this.props.onQuickAnsLinkForm}
                            onLaunchExternalNavigation={this.props.onLaunchExternalNavigation}  ///////// URL CONFIG
                            gateway_login_page={this.props.gateway_login_page}   ////IFrame login and client config details
                            onDisplayDrugInteractionsWithDefaultState={this.props.onDisplayDrugInteractionsWithDefaultState}
                            props={this.props}
                            onClearSearchTerm={this.props.onClearSearchTerm}
                        />
                    </div>

                    <div className="mdx-chatbot-input-row">
                        <form name="chatbot_form" onSubmit={this.handleSubmit}>
                            <TextInput
                                size="lg"
                                disabled={!isResponseLoading}
                                key={''+this.props.user_input_key}
                                ref={this.inputFieldRef}
                                value={this.state.inputValue}
                                onChange={evt => this.updateInputValue(evt)}
                                {...ChatbotInputProps}/>
                            <div>
                            <ChevronRight
                            className={!isResponseLoading ? "mdx-chatbot-btn-disabled" : ""}
                            onClick={()=>this.handleSubmit()} id="mdx-right-icon"/></div>
                        </form>
                    </div>

                    <CommonOverflowModalPopups/>
                </div>
            </div>
        );
    } //end render()
} //endclass




const ScrollWindow = ({
                          conversation_turns,
                          subscriptionInfo,
                          clientConfig,
                          credential,
                          onInDepthLinkForm,
                          onQuickAnsLinkForm,
                          onLaunchExternalNavigation , ///////////////// URL CONFIG
                          gateway_login_page,   ////IFrame login and client config details
                          onDisplayDrugInteractionsWithDefaultState,
                          props,
                          onClearSearchTerm
}) => {
    const conversation_turns_count = conversation_turns.length

    // See: https://reactjs.org/docs/hooks-effect.html
    useEffect(() => {
        const targetDiv = document.getElementById("lastTurnScrollMarker");
        const scrollingChatDiv = document.getElementById("scrollingChat");
            
        if (targetDiv) {
            scrollingChatDiv.scrollTo(0, targetDiv.offsetTop - scrollingChatDiv.offsetTop);
        }
    });

    return <div id="scrollingChat"  className="mdx-chatbot-scrolling-div chatbotResponses">
        {
            conversation_turns.map((turn, index) =>
                <RenderTurn
                    key={index} {...turn}
                    isLastTurn={index === (conversation_turns_count-1)}
                    subscriptionInfo={subscriptionInfo}
                    clientConfig={clientConfig}
                    credential={credential}
                    onInDepthLinkForm={onInDepthLinkForm}
                    onQuickAnsLinkForm={onQuickAnsLinkForm}
                    onLaunchExternalNavigation={onLaunchExternalNavigation} //////// URL
                    gateway_login_page={gateway_login_page}   ////IFrame login and client config details
                    onDisplayDrugInteractionsWithDefaultState={onDisplayDrugInteractionsWithDefaultState}
                    props={props}
                    onClearSearchTerm={onClearSearchTerm}
                />
            )
        }
    </div>

}

export const ChatbotPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatbotPagePresentation)

export default ChatbotPage;
