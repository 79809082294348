/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, createRef, Fragment} from 'react';

import queryString from 'query-string'
import {Tab, Tabs, TabList, TabPanels, TabPanel} from "@carbon/react";
import {
  get,
  isEmptyObject
} from "../../utils";
import {InDepthPage} from "../InDepthPage2";
import {DrugPointsPage} from "../DrugPointsPage2";
import {connect} from "react-redux";
import
  StandaloneSubscriptionModal
  from "../../components/standalone-subscription-modal/StandaloneSubscriptionModal";
import {CommonFooter} from "../../components/common/footers/Footer";
import HomePage from '../HomePage/HomePage';
import {makeSetRecentSearchAction} from '../HomePage/home-page-utils/home-page-redux';
import {prepareRecentSearchLink} from '../HomePage/home-page-utils/home-page-util';



const mapStateToProps = state => {
  const state_results = {
    has_indepth_subscription: get(state, 'login_page.mdxAuthenticationJson.indepthSubscription', 'no')==='yes',

    current_state: state.drug_monograph_page,
    current_subscription_modal:get(state.drug_monograph_page,'subcription_popup_data',false),
    subcription_intial_state:get(state.drug_monograph_page,'subcription_intial_state',true),
    subscription_state: get(state.login_page, 'mdxAuthenticationJson'),
    drug_points_page: get(state.drug_points_page),
    drug_points_data: get(state.drug_points_page, 'all_quick_ans_data',{}),
    in_depth_data: get(state.in_depth_page, 'all_in_depth_data',{}),
    in_depth_page: get(state.in_depth_page),
    recentSearch: get(state, 'home_page.recent_search', new Map()),
    state:state
  }
  return state_results
}
const mapDispatchToProps = dispatch => {
  return {
    onSaveRecentSearch: (location, recentSearch) => {
      dispatch (makeSetRecentSearchAction(prepareRecentSearchLink(location, recentSearch)))
    }
  }
}

/**
 * This is the top level Monograph component displayed
 * in the Chatbot Mobile PWA
 */
export class DrugMonographTabbedPagePresentation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open:false
    };
    this.switchInDepthQuick = this.switchInDepthQuick.bind(this)
    this.showModalSubscriptionMesssage = this.showModalSubscriptionMesssage.bind(this)
    this.scrollingDivRef = createRef()
    this.tabParent = createRef()
  } //endconstructor

  componentDidMount() {
    this.props.onSaveRecentSearch(this.props.location, this.props.recentSearch);
  }

  showModalSubscriptionMesssage = () => {
    this.setState({open:true})
  }

  switchInDepthQuick() {

    const loc_search = get(this.props, 'location.search')
    console.log("switchInDepthQuick: loc_search=", loc_search)
    if (!loc_search) {
      return
    }
    const initial_url_params = queryString.parse(loc_search)

    const drugName_encoded = encodeURIComponent(initial_url_params.drugName)
    const topicList = initial_url_params.topicId
    const current_document_id = initial_url_params.documentId
    const contentSetId = initial_url_params.contentSetId

    if(contentSetId !== 100 && topicList === "IVcompatibility") {
        const drugName = this.props.drug_points_page.drugName
        const url = `/ivCompatibilitySearch?drug_ids=0&iv_drug_names=${drugName}&isFromMonograph=true`
        this.props.history.push(url);
    } else {
      let linkUrl
      if (contentSetId === '100') {
        // currently viewing quick answers URL ... compose an in depth linkUrl
        let inDepthDocumentId =
            initial_url_params.inDepthLinkDocumentId
            ||
            initial_url_params.drugEvalsId
        const quick_data = get(this.props.drug_points_page, 'drug_points_data', {})
        if (!inDepthDocumentId) {
          inDepthDocumentId = quick_data.detailsInDepthLinksSection;
        }
        linkUrl = `/inDepth?contentSetId=31&drugName=${drugName_encoded}&documentId=${inDepthDocumentId}&topicId=${topicList}&quickLinkDocumentId=${current_document_id}`
      }
      else
      {
        // currently viewing in depth URL ... compose a quick answers linkUrl
        const quickAnswersDocumentId = initial_url_params.quickLinkDocumentId
        linkUrl = `/drugPoints?contentSetId=100&drugName=${drugName_encoded}&documentId=${quickAnswersDocumentId}&topicId=${topicList}&drugEvalsId=${current_document_id}`
      }
      console.log("switchInDepthQuick: linkUrl=",linkUrl)
      this.props.history.push(linkUrl)
    }
  }
  getDrugEvalsIdFromQuickData(quick_data, all_quick_data, is_indepth, initial_url_params) {
    let drugEvalsId = quick_data.detailsInDepthLinksSection;
    if (!drugEvalsId) {
      let documentId = is_indepth
        ?  get(this.props.in_depth_page, 'in_depth_data.documentId',{})
        :  get(initial_url_params, 'documentId',{})
      let contentSetId = is_indepth
        ? get(this.props.in_depth_page, 'in_depth_data.contentSetId',{})
        : get(initial_url_params, 'contentSetId',{})
      const id = documentId + '-' + contentSetId;
      quick_data = all_quick_data && !isEmptyObject(all_quick_data) ? all_quick_data[id] : {};
      drugEvalsId = quick_data && quick_data.detailsInDepthLinksSection;
    }
    return drugEvalsId;
  }

  render() {

    const loc_search = get(this.props,'location.search','')
    console.log("render: loc_search=",loc_search)
    const initial_url_params = queryString.parse(loc_search)
    console.log(initial_url_params)

    const topicId = initial_url_params.topicId
    const is_indepth = initial_url_params.contentSetId === '31'

    const quick_documentID=is_indepth?
      initial_url_params.quickLinkDocumentId
      :initial_url_params.documentId
    let drugEvalsId = is_indepth?initial_url_params.documentId:
      (initial_url_params.drugEvalsId||initial_url_params.inDepthLinkDocumentId)

    let selected_children=is_indepth?1:0;

    console.log('state======================',this.props.state)
    const all_quick_data = get(this.props,'drug_points_data',{});
    let quick_data = get(this.props.drug_points_page, 'drug_points_data',{})
    if (!drugEvalsId) {
      drugEvalsId = this.getDrugEvalsIdFromQuickData(quick_data, all_quick_data, is_indepth, initial_url_params);
    }
    console.log(
      'all_quick_data&&&&&&&&&&&&&&&&&&&&&&&&&=',all_quick_data,
      'quick_data######################=',quick_data,
      'qucik aswers doc ID $$$$$$$$$$$$$$$$$=',quick_documentID
    )
    const quick_disable = !quick_documentID;

    const indepth_disable = this.props.has_indepth_subscription && !drugEvalsId;

    const outer_classname = 'main-container' + (topicId === 'IVcompatibility' ? ' mdx-ivcompatibility-data' : '')
    return <div className={outer_classname}>
      <div className="empty-container"></div>
      <div className="main-content">
        <div
          key="mdx-scroll-area"
          ref={this.scrollingDivRef}
          className="mdx-scroll-area"
          id="quickAnswersScrollArea"
        >
          <HomePage isMonographPage={true} {...this.props} />
          <Tabs
            defaultSelectedIndex = {0}
            selectedIndex = {selected_children}
            tabContentClassName = "tabs-inner-content"
            ref = {this.tabParent}
            onChange = {index => console.log("444444444444444444444444444444444444 index=",index)}
          >
            <TabList contained={true}>
              <Tab id="tab-1"
                disabled={quick_disable}
                onClick={
                  () => {
                    if (is_indepth) {
                      this.switchInDepthQuick()
                    }
                  }
                }
              >
                  Quick Answers
              </Tab>
              <Tab id="tab-2"
                 disabled={indepth_disable}
                //  disabled={true}
                 onClick={
                   () => {
                     if (!is_indepth) {
                       if (this.props.has_indepth_subscription) {
                         this.switchInDepthQuick()
                       } else {
                         this.showModalSubscriptionMesssage()
                         this.tabParent.current.setState({selected:0})
                       }
                     }
                   }
                 } 
              >
                  In-Depth Answers
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {
                  is_indepth ? <Fragment/> :
                    <DrugPointsPage
                      {...this.props}
                      scrollingDivRef={this.scrollingDivRef}
                    />
                }
              </TabPanel>
              <TabPanel>
                {
                  is_indepth ? <InDepthPage
                    {...this.props}
                    scrollingDivRef={this.scrollingDivRef}
                  /> : <Fragment/>
                }</TabPanel>
            </TabPanels>
          </Tabs>
          <CommonFooter/>
          <StandaloneSubscriptionModal
            isOpened={this.state.open}
            closeHandler={() => {
              this.setState({open: false})
            }
            }
          />
        </div>
      </div>
    </div>
  } //endrender
}//endclass
const DrugMonographTabbedPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrugMonographTabbedPagePresentation);
export default DrugMonographTabbedPage