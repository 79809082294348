/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import {
  getInitialState,
  switchTopicId,
  updateStateDrugPointsResponse,
  EMPTY_STATE,
  updateStateAllQuickAnsResponse,
  ALL_QUICK_ANS_DATA_PATH,
  DRUG_POINTS_CALL_IN_PROGRESS_PATH,
  DRUG_POINTS_CALL_ERROR_IN_CHATBOT_PATH,
  DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH
} from "./drugPointsState";

import {
  makeDrugDexCallInProgress,
  makeDrugDexCallErrorInChatbot,
  makeDrugDexCallErrorInDrugMonograph
} from "../../InDepthPage2/in-depth-utils";

import {
  immutable_set,
  get,
  isEmptyObject,
  enhanced_get,
  util_alert,
  areArraysEqual, getArray
} from "../../../utils";
import {
  getDrugInteractionPromise,
  getDrugPointsData,
  getDrugdexEvalsData,
  getIVCompatibilityPromise
} from "../../../services";
import {
  makeALLQuickAnsDrugDataResponseAction_chatbot,
  makeALLInDepthDrugDataResponseAction_chatbot,
  makeSetChatbotReturnTarget
} from "../../ChatbotPage/chatbot-utils";
import {
  makeApplyNewFilterAction,
  makeChangeInteractionObjectsAction,
  makeDrugInteractionResponseAction
} from "../../DrugInteractionPage/drug-interaction-utils";
import {
    makeIVCompatSummaryAction,
    makeIVCompSetDrugsAndSolutionsAction,
    makeUpdateCurrentDrugFiltersAction,
    makeUpdateCurrentSolutionFiltersAction,
} from "mdx-ivcomp-common-ui/dist/src/pages/IVCompatibilityPages/iv-compatibility-summary-page/iv-compatibility-summary-utils/iv-compatibility-summary-redux";
import {getFilterObjectForDefinedDrugsAndAllergies, result_types} from "../../../utils/commonIvDiUtils";
import {handleError, handleErrorStatuses} from "../../../utils/internal-error-handler";
import {
  GENERIC_ERROR_MESSAGE,
  OTHER_ERROR_TYPE
} from "../../../components/common/constants";
import {
    DRUG_POINTS_URL_PREFIX,
    IN_DEPTH_URL_PREFIX,
    DRUG_POINTS_CONTENT_SET_ID,
    isDrugPointsLoading,
    hasDrugPointsErrorInChatbot,
    hasDrugPointsErrorInDrugMonograph,
    isDrugDexLoading,
    hasDrugDexErrorInChatbot,
    replaceParamsInStateFromUrl
} from '../../../utils/commonUtils';
import {
  ACTIVE_STATUS,
  DRUG_POINTS_PAGE_NAME,
  ERROR_STATUS,
  LOADING_DESCRIPTION,
  LOADING_ERROR_DESCRIPTION,
  makeResponseLoadingAction,
  NONE_STATUS,
  IN_DEPTH_PAGE_NAME
} from "../../../components/response-loading/redux/response-loading-redux";

// Action constants ...
export const A = {
    MODAL_POPUP_ACTION: 'QUICK_MODAL_POPUP_ACTION',
    SWITCH_TOPIC_ID: 'QUICK_SWITCH_TOPIC_ID',
    DRUG_DATA_RESPONSE: 'QUICK_DRUG_DATA_RESPONSE',
    DRUG_POINTS_RESPONSE: 'QUICK_DRUG_POINTS_RESPONSE',
    SECTION_MODAL_POPUP_ACTION: 'QUICK_SECTION_MODAL_POPUP_ACTION',
    HIDDEN_TEXT_ID_ACTION: 'QUICK_HIDDEN_TEXT_ID_ACTION',
    CLEAR_QUICK_STATE: 'QUICK_CLEAR_QUICK_STATE',
    SINGLE_DRUG_INTERACTION_DATA_RESPONSE: 'QUICK_SINGLE_DRUG_INTERACTION_DATA_RESPONSE',
    IV_COMPATIBILITY_MONOGRAPH_LOADING: 'QUICK_IV_COMPATIBILITY_MONOGRAPH_LOADING',
    IV_COMPATIBILITY_MONOGRAPH_DATA_RESPONSE: 'QUICK_IV_COMPATIBILITY_MONOGRAPH_DATA_RESPONSE',
    DRUG_EVALS_ID:'QUICK_DRUG_EVALS_ID',
    ALL_QUICK_ANS_RESPONSE: 'QUICK_ALL_QUICK_ANS_RESPONSE',
    DRUG_POINTS_CALL_IN_PROGRESS: 'QUICK_DRUG_POINTS_CALL_IN_PROGRESS',
    DRUG_POINTS_CALL_ERROR_IN_CHATBOT: 'QUICK_DRUG_POINTS_CALL_ERROR_IN_CHATBOT',
    DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH: 'QUICK_DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH'
}

// Action creators ...
export function makeModalPopupAction(citationId='') {
    return {
        type: A.MODAL_POPUP_ACTION,
        citationId
    }
}

export function makeSwitchTopicAction(newTopicId='', initial_url_params={}) {
    return {
        type: A.SWITCH_TOPIC_ID,
        newTopicId,
        initial_url_params
    }
}

export function makeDrugSingleInteractionResponseAction(single_interaction_response_json={}) {
    return {
        type: A.SINGLE_DRUG_INTERACTION_DATA_RESPONSE,
        single_interaction_response_json
    }
}

export function makeDrugDataResponseAction(drug_points_response_json={}, allTopics=false) {
    return {
        type: A.DRUG_POINTS_RESPONSE,
        drug_points_response_json,
        allTopics
    }
}

export function updateDrugEvalsIdAction(drugEvalsId){
    return {
        type:A.DRUG_EVALS_ID,
        drugEvalsId
    }
}

export function makeSectionModalPopupAction(fda_popup_id=''){
    return {
        type: A.SECTION_MODAL_POPUP_ACTION,
        fda_popup_id
    }
}

export function makeHiddenTextModalPopupAction(hidden_text_id=''){
    return {
        type: A.HIDDEN_TEXT_ID_ACTION,
        hidden_text_id
    }
}

export function makeQuickClearStateAction() {
    return {
        type: A.CLEAR_QUICK_STATE
    }
}

export function makeIvCompatibilityMonographLoadingAction(loading = false) {
    return {
        type: A.IV_COMPATIBILITY_MONOGRAPH_LOADING,
        loading
    }
}

export function makeIvCompatibilityMonographDataResponseAction(iv_compatibility_response_json={}) {
    return {
        type: A.IV_COMPATIBILITY_MONOGRAPH_DATA_RESPONSE,
        iv_compatibility_response_json
    }
}

export function makeALLQuickAnsResponseAction(quick_ans_response_json={}, documentId="", contentSetId="") {
    return {
        type: A.ALL_QUICK_ANS_RESPONSE,
        quick_ans_response_json,
        documentId,
        contentSetId
    }
}

export function makeDrugPointsCallInProgress(request) {
    return {
        type: A.DRUG_POINTS_CALL_IN_PROGRESS,
        request
    }
}

export function makeDrugPointsCallErrorInChatbot(request) {
    return {
        type: A.DRUG_POINTS_CALL_ERROR_IN_CHATBOT,
        request
    }
}

export function makeDrugPointsCallErrorInDrugMonograph(request) {
    return {
        type: A.DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH,
        request
    }
}

// Reducers ...
/*
WARNING! WARNING! WARNING!

Reducers (and methods invoked from a reducer)
should only return a new state obtained by using immutable_set() on the old state

Reducers should *NEVER* modify any part of the old state!

It is OK to directly modify a portion of the new state that has already been modified by immutable_set() ...
but be *VERY* *VERY* *CAREFUL* to never modify an object that is shared between new and old state!

A *lot* of React functionality depends on reliable detecting the exact differences between the new and old states

WARNING! WARNING! WARNING!
 */
export function modalPopupReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'modal_reference_index',
        action.citationId
    )
}

export function switchTopicReducer(current_state={}, action={}) {
    //const method = 'switchTopicReducer'

    let good_state = current_state
    let good_topic_id = action.newTopicId

    // non-empty initial_url_params will override current state ...
    // this can happen if user clicks another quick link to go to a different drug or section
    if (action.initial_url_params && !isEmptyObject(action.initial_url_params)) {
        good_state = getInitialState(action.initial_url_params)
        good_topic_id = action.initial_url_params.topicId
        good_state.DRUG_POINTS_CALL_IN_PROGRESS = current_state.DRUG_POINTS_CALL_IN_PROGRESS
        good_state.DRUG_POINTS_CALL_ERROR_IN_CHATBOT = current_state.DRUG_POINTS_CALL_ERROR_IN_CHATBOT
        good_state.DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH = current_state.DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH
    }
    // This will make sure the all_quick_ans_data will be available for next state to use
    good_state.all_quick_ans_data = current_state.all_quick_ans_data ? current_state.all_quick_ans_data : null;
    return switchTopicId(
        good_state,
        good_topic_id
    )
}

export function drugDataResponseReducer(current_state={}, action={}) {
  console.log("&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&  drugpoint page")
    return updateStateDrugPointsResponse(
        current_state,
        action.drug_points_response_json,
        action.allTopics
    )
}

export function singleDrugInteractionResponseReducer(current_state={}, action={}) {
    return immutable_set(
        current_state,
        'drug_points_single_interaction',
        action.drug_points_response_json
    )
}

export function sectionModalPopupReducer(current_state={},action={}){
    return immutable_set (
        current_state,
        'section_popup_data',
        action.fda_popup_id
    )
}

export function hiddenTextModalPopupReducer(current_state={},action={}){
    return immutable_set (
        current_state,
        'hidden_text_id',
        action.hidden_text_id
    )
}

export function clearQuickStateReducer(current_state={},action={}){
    return getInitialState({
        topicId:'',
        drugName: '',
        documentId:'',
        contentSetId: ''
    })
}

export function updateDrugEvalsIdReducer(current_state={}, action={}){
    return immutable_set(
        current_state,
        'drugEvalsId',
        action.drugEvalsId
    )
}

export function allQuickAnsResponseReducer(current_state={}, action={}) {
    return updateStateAllQuickAnsResponse(
        current_state,
        action.quick_ans_response_json
    )
}

export function updateDrugPointsCallInProgressReducer(current_state={}, action={}) {
    //Check if the in_depth_response for this document and content already available
    const in_progress_key = DRUG_POINTS_CALL_IN_PROGRESS_PATH + '.' + action.request.documentId + '-'
        + action.request.contentSetId;
    const in_progress_flag = get(current_state, in_progress_key, false);
    if (in_progress_flag) {
        return current_state;
    }
    return immutable_set(current_state, in_progress_key, true);
}

export function updateDrugPointsCallErrorInChatbotReducer(current_state={}, action={}) {
    const hasErrorKey = DRUG_POINTS_CALL_ERROR_IN_CHATBOT_PATH + '.' + action.request.documentId + '-'
        + action.request.contentSetId;
    const hasError = get(current_state, hasErrorKey, false);
    if (hasError === action.request.hasError) {
        return current_state;
    }
    return immutable_set(current_state, hasErrorKey, action.request.hasError);
}

export function updateDrugPointsCallErrorInDrugMonographReducer(current_state={}, action={}) {
    const hasErrorKey = DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH_PATH + '.' + action.request.documentId + '-'
        + action.request.contentSetId;
    const hasError = get(current_state, hasErrorKey, false);
    if (hasError === action.request.hasError) {
        return current_state;
    }
    return immutable_set(current_state, hasErrorKey, action.request.hasError);
}

// redux-thunk THUNKs
export function handleCommunicationsResponse(response, props, dispatch, getState) {
  const status = response.status;
  if(status === 200) {
    return response.json();
  } else {
    if (status === 403) {
      dispatch(makeQuickClearStateAction())
    }
    let mutable_props = {...props};
    mutable_props.error_type = OTHER_ERROR_TYPE
    handleErrorStatuses(status, mutable_props, dispatch, getState)
  }
}

export function makeGoToChatbotPageActionThunk(props={}) {
    return (dispatch) => {
        const currentLocation = props.location;
        dispatch(makeSetChatbotReturnTarget('DrugPoints', currentLocation));
        props.history.push('/chatbot')
    }
}

export const loadIvSubscriptionData = (dispatch, drugName, login_page, props, getState) => {
    dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
    let agent_names = [drugName]
    return getIVCompatibilityPromise(
      {
          agent_names, result_types, login_page
      }
    ).then(function (response) {
        if (response.status === 200) {
            return response.json();
        } else {
            dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            if (response.status === 403) {
              dispatch(makeQuickClearStateAction())
            }
            handleCommunicationsResponse(response, props, dispatch, getState)
        }
    }).then(iv_compatibility_response_json => {
        if (iv_compatibility_response_json) {
          dispatch(
            makeIVCompatSummaryAction(
              iv_compatibility_response_json
            ))
          dispatch(makeIVCompSetDrugsAndSolutionsAction(
            agent_names,
            [] // solution names empty
          ))
          const drug_filters = agent_names.map(() => true);
          dispatch(makeUpdateCurrentDrugFiltersAction(drug_filters));
          dispatch(makeUpdateCurrentSolutionFiltersAction([]));
          dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
        }
    });
}

export const shouldLoadIVSubscriptionData = subscription_data => {
  return get(subscription_data, 'IVCompatibilitySolutionTPNTNA', 'no') === 'yes'
    && get(subscription_data, 'IVCompatibilityYSiteAdmixtureSyringe', 'no') === 'yes'
}

const loadDocuments = (dispatch, props, documentId, contentSetId, drugname, login_page, allTopics, getState) => {
    dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
    dispatch(makeDrugPointsCallInProgress({documentId, contentSetId}));
    let hasError = false;
    dispatch(makeDrugPointsCallErrorInChatbot({documentId, contentSetId, hasError}));
    dispatch(makeDrugPointsCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
    return  getDrugPointsData(
      {
        documentId,
        contentSetId,
        drugname
      },
      login_page
    ).then(function (response) {
        if (response.status === 200) {
            return response.json();
        } else {
            dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            hasError = true;
            dispatch(makeDrugPointsCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
            if (window.location.hash.startsWith(DRUG_POINTS_URL_PREFIX)) {
                handleCommunicationsResponse(response, props, dispatch, getState)
            }
        }
        }).then(result => {
            if (result) {
                dispatch(makeALLQuickAnsDrugDataResponseAction_chatbot(result));
                dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                const drugEvalsId = get(result,'detailsInDrugdexSection.xref.id','');
                dispatch(updateDrugEvalsIdAction(drugEvalsId))
                const contentSetId = '31';
                const documentId = result.detailsInDepthLinksSection;
                if (get(login_page, 'mdxAuthenticationJson.indepthSubscription') !== 'yes'
                    || !documentId
                    || (isDrugDexLoading(getState(), documentId, contentSetId)
                        && !hasDrugDexErrorInChatbot(getState(), documentId, contentSetId))) {
                  return result;
                }
                dispatch(makeDrugDexCallInProgress({documentId, contentSetId}));
                dispatch(makeDrugDexCallErrorInChatbot({documentId, contentSetId, hasError}));
                dispatch(makeDrugDexCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
                dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
                return getDrugdexEvalsData(
                  {
                    documentId,
                    contentSetId,
                    drugname
                  },
                  login_page
                ).then(function(response) {
                  if (response.status === 200) {
                    return response.json();
                  } else {
                    dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
                    hasError = true;
                    dispatch(makeDrugDexCallErrorInDrugMonograph({documentId, contentSetId, hasError}));
                    if (window.location.hash.startsWith(DRUG_POINTS_URL_PREFIX)) {
                      handleCommunicationsResponse(response, props, dispatch, getState)
                    }
                  }
                }).then(result => {
                  if (result) {
                    dispatch(makeALLInDepthDrugDataResponseAction_chatbot(result))
                    dispatch(makeResponseLoadingAction(IN_DEPTH_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
                  }
                }).catch(error => {
                  handleError({props, message:GENERIC_ERROR_MESSAGE, dispatch},
                    makeDrugDataResponseAction(
                      JSON.stringify("<heading>An error occurred. Please try again. </heading>."), allTopics
                    ));
                  util_alert(
                    "An error occurred. Please try again.",
                    OTHER_ERROR_TYPE,
                    dispatch,
                    getState
                  );
                })
              }
            }).catch(error => {
               dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
               handleError({props, message:GENERIC_ERROR_MESSAGE, dispatch},
                 makeDrugDataResponseAction(
                   JSON.stringify("<heading>An error occurred. Please try again. </heading>."), allTopics
                 ));
                util_alert(
                    "An error occurred. Please try again.",
                    OTHER_ERROR_TYPE,
                    dispatch,
                    getState
                );
            })
}

export const loadDrugInteractionData = (dispatch, login_page, drugs, drugName, documentId, props, getState) => {
    dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
    return getDrugInteractionPromise(
        {
            drugs,
            login_page
        }
    ).then(function(response) {
        if (response.status === 200) {
            return response.json();
        } else {
            dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ERROR_STATUS, LOADING_ERROR_DESCRIPTION));
            handleCommunicationsResponse(response, props, dispatch, getState)
        }
    }).then(drug_points_response_json => {
        if (drug_points_response_json) {
            dispatch(makeDrugInteractionResponseAction(drug_points_response_json));
            dispatch(makeChangeInteractionObjectsAction({drugs: [{id: documentId, name: drugName}], allergies: []}))
            const drugInteractionsFilterObject = getFilterObjectForDefinedDrugsAndAllergies([documentId], []);
            dispatch(makeApplyNewFilterAction(drugInteractionsFilterObject));
            dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
        }
    });
}

const DRUG_INTERACTION_OBJECTS_PATH = 'drug_interaction_page.drug_interaction_objects';
const DRUG_INTERACTION_DATA_PATH = 'drug_interaction_page.drug_interaction_data';
const IV_COMP_FILTERED_DRUG_NAMES_PATH = 'iv_compatibility_page.filtered_drug_names';
export const IV_COMP_SUMMARY_PAGE_PATH = 'iv_compatibility_page.iv_comp_summary_page';

export function makeSwitchTopicActionThunk(new_topic='', initial_url_params={}, props={}) {
    return (dispatch,getState) => {

        // set up the new topic information in the redux state
        dispatch(makeSwitchTopicAction(new_topic, initial_url_params))
        let login_page = {};
        let current_state = getState()
        console.debug('Before makeSwitchTopicActionThunk....................current_state=',current_state);
        // handle combined reducers ...
        if (current_state.drug_points_page) {
            login_page = current_state.login_page;
            console.debug('makeSwitchTopicActionThunk',': login_page=',login_page)
            current_state = current_state.drug_points_page;
            if (props.urlParams) {
                replaceParamsInStateFromUrl(current_state, props.urlParams);
            }
        }

        console.debug('makeSwitchTopicActionThunk....................current_state=',current_state);
        const {
            contentSetId,
            documentId,
            reference_data,
            drugName,
            // TODO: get creds from some other source
        } = current_state
        const subscription_data=get(props, 'subscription_state')
        // const topicList = reference_data.topic_ids
        //If this is a drug interaction topic, don't do drugData!! Otherwise, definitely *do* do drug data.
        if (reference_data.template_type === "drug_interactions") {
           if (get(subscription_data, 'drugInteractions', 'no') === 'yes') {
               let drugs = [documentId];
               const lastDrugInteractionObjects = get(getState(), DRUG_INTERACTION_OBJECTS_PATH, {});
               const drugInteractionData = get(getState(), DRUG_INTERACTION_DATA_PATH, {});
               if ((window.location.hash.startsWith(IN_DEPTH_URL_PREFIX)
                   || !isEmptyObject(drugInteractionData))
               && (getArray(lastDrugInteractionObjects, 'allergies').length === 0
               && getArray(lastDrugInteractionObjects, 'drugs').length === 1
               && lastDrugInteractionObjects.drugs[0].id === documentId)) {
                loadDrugPointsAndDrugdexEvalsData(dispatch, props, documentId, contentSetId, drugName, login_page, getState, current_state);
                    return current_state;
               }
               loadDrugPointsAndDrugdexEvalsData(dispatch, props, documentId, contentSetId, drugName, login_page, getState, current_state);
               return loadDrugInteractionData(dispatch, login_page, drugs, drugName, documentId, props, getState);
           }
        } else if (reference_data.template_type === "iv_compatibility"
            && shouldLoadIVSubscriptionData(subscription_data)) {
          const filteredDrugNames
              = get(getState(), IV_COMP_FILTERED_DRUG_NAMES_PATH, []);
          const ivCompatibilitySummaryPageData
              = get(getState(), IV_COMP_SUMMARY_PAGE_PATH, {});
          if (areArraysEqual(filteredDrugNames, [drugName])
              && (window.location.hash.startsWith(IN_DEPTH_URL_PREFIX)
                  || !isEmptyObject(ivCompatibilitySummaryPageData))) {
              const state = getState();
              loadDrugPointsAndDrugdexEvalsData(dispatch, props, documentId, contentSetId, drugName, login_page, getState, current_state);
              return state;
          } else {
              return loadIvSubscriptionData(dispatch, drugName, login_page, props, getState);
          }
        } else {
             let allTopics  = false;
             console.log('###############################$$$$$$$$$$$',documentId)
             console.log('###############################$$$$$$$$$$$',current_state)
            const old_key = '.'+documentId + '-' + contentSetId;
            const responseFromCache = enhanced_get(current_state, ALL_QUICK_ANS_DATA_PATH+old_key, '');

             // Checking if the current topics for this drug are already cached, if not make proxy call to load the data
             if (responseFromCache){
               allTopics = true;
               console.debug("Method : %s , DrugMonoGraph data is available in state for topic : %s", 'makeSwitchTopicActionThunk', new_topic);
               dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, ACTIVE_STATUS, LOADING_DESCRIPTION));
               dispatch(
                   makeDrugDataResponseAction(
                       responseFromCache, allTopics
                   ))
               dispatch(makeResponseLoadingAction(DRUG_POINTS_PAGE_NAME, NONE_STATUS, LOADING_DESCRIPTION));
//                   TODO : HOW TO DO BELOW STEPS
//                                   let drugEvalsId= get(drug_points_response_json,'detailsInDrugdexSection.xref.id','')
//                                   dispatch(updateDrugEvalsIdAction(drugEvalsId))
             } else if (!isDrugPointsLoading(getState(), documentId, contentSetId)
                 || hasDrugPointsErrorInChatbot(getState(), documentId, contentSetId)
                 || hasDrugPointsErrorInDrugMonograph(getState(), documentId, contentSetId)) {
               return loadDocuments(dispatch, props, documentId, contentSetId, drugName, login_page, allTopics, getState);
             }
        } //endelse ==="iv_compatibility"
    } //endreturn return (dispatch,getState) => {}
}

const loadDrugPointsAndDrugdexEvalsData = (dispatch, props, documentId, contentSetId, drugName, login_page, getState, current_state) => {
    const drug_points_key = '.' + documentId + '-' + DRUG_POINTS_CONTENT_SET_ID;
    const drug_points_data = enhanced_get(current_state, ALL_QUICK_ANS_DATA_PATH + drug_points_key);
    if ((!drug_points_data || isEmptyObject(drug_points_data))
        && (!isDrugPointsLoading(getState(), documentId, contentSetId)
            || hasDrugPointsErrorInChatbot(getState(), documentId, contentSetId)
            || hasDrugPointsErrorInDrugMonograph(getState(), documentId, contentSetId))) {
        loadDocuments(dispatch, props, documentId, contentSetId, drugName, login_page, false, getState);
    }
}

// NOTE: reducers can be used to initialize state
export function drug_points_page(
    current_state=EMPTY_STATE, // this is a good initial state since we don't know what drug to retrieve
    action={}
    ) {
    switch (action.type) {
        case A.MODAL_POPUP_ACTION:
            return modalPopupReducer(current_state, action)
        case A.SWITCH_TOPIC_ID:
            return switchTopicReducer(current_state, action)
        case A.DRUG_DATA_RESPONSE:
            return drugDataResponseReducer(current_state, action)
        case A.SECTION_MODAL_POPUP_ACTION:
            return sectionModalPopupReducer(current_state, action)
        case A.HIDDEN_TEXT_ID_ACTION:
            return hiddenTextModalPopupReducer(current_state, action)
        case A.CLEAR_QUICK_STATE:
            return clearQuickStateReducer(current_state, action)
        case A.SINGLE_DRUG_INTERACTION_DATA_RESPONSE:
            return singleDrugInteractionResponseReducer(current_state, action);
        case A.DRUG_EVALS_ID:
            return updateDrugEvalsIdReducer(current_state, action);
        case A.ALL_QUICK_ANS_RESPONSE:
            return allQuickAnsResponseReducer(current_state, action)
        case A.DRUG_POINTS_RESPONSE:
            return drugDataResponseReducer(current_state, action)
        case A.DRUG_POINTS_CALL_IN_PROGRESS:
            return updateDrugPointsCallInProgressReducer(current_state, action)
        case A.DRUG_POINTS_CALL_ERROR_IN_CHATBOT:
            return updateDrugPointsCallErrorInChatbotReducer(current_state, action)
        case A.DRUG_POINTS_CALL_ERROR_IN_DRUG_MONOGRAPH:
            return updateDrugPointsCallErrorInDrugMonographReducer(current_state, action)
        default:
            return current_state
    }
}
