/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
import React, {Component, createRef, Fragment} from 'react';
import {connect} from "react-redux";
import queryString from 'query-string'
import {makeUpdateSelectedDrugsAction} from './drug-interaction-utils'

import { Link } from 'react-router-dom';

import { get, isEmptyObject, getArray, getDecodedURIComponents, areArraysEqual } from '../../utils';

import {
    makeDrugInteractionGoToChatbotActionThunk,
    makeApplyNewFilterAction,
    makeShowFilterPanelAction,
    makeChangeInteractionObjectsAction,
    makeDrugInteractionActionThunk
} from "./drug-interaction-utils";
import DrugInteractionSelectionList from "./drug-interaction-components/DrugInteractionSelectionList";
import { DrugInteractionFilterModalParent } from './drug-interaction-components/filterModal'
import CommonOverflowModalPopups from "../../components/common-overflow-menu/CommonOverflowModalPopups";
import {ToastNotification} from "@carbon/react";
import DrugInteractionsFilterPill from "./drug-interaction-components/DrugInteractionFilterPill";
import {
    makeDiShowUnableToDeleteMessageAction,
    DRUG_INTERACTION_ERROR
} from "./drug-interaction-utils/drug-interaction-page-redux";
import {diRemoveItem, getFilterObjectForDefinedDrugsAndAllergies, getItemNames} from "../../utils/commonIvDiUtils";
import {SelectedItem} from "../../components/common/SelectedItem";
import {CommonFooter} from "../../components/common/footers/Footer";
import {DRUG_INTERACTIONS_SUMMARY_PAGE_NAME} from "../../components/response-loading/redux/response-loading-redux";
import {DrugInteractionFilterComponent} from "./drug-interaction-components/DrugInteractionFilterComponent";

const { ChevronLeft } = require('@carbon/react/icons');

const mapStateToProps = state => {
    console.debug('mapStateToProps: state=',state)
  const drugInteractionPage = state.drug_interaction_page;
  const state_results = {
        current_state: drugInteractionPage,
        current_warnings: get(state.drug_points_page,'warnings',[]),
        subscription_state: get(state.login_page, 'mdxAuthenticationJson'),
        interaction_objects: get(drugInteractionPage, 'drug_interaction_objects', {}),
        filter_status: get(drugInteractionPage, 'filter_status', {}),
        show_unable_to_delete: get(drugInteractionPage, 'show_unable_to_delete', false),
        diSummaryPageResponseLoading: get(state, `inline_loading.${DRUG_INTERACTIONS_SUMMARY_PAGE_NAME}`, {})
  }
    console.debug(state_results);
    return state_results
}

const mapDispatchToProps = dispatch => {
    return {
        onDisplayInteraction: (initial_url_params={}, props) => {
            let drug_ids = getArray(initial_url_params, 'drug_ids');
            let drug_names = getDecodedURIComponents(getArray(initial_url_params, 'drug_names'));

            let allergy_ids = getArray(initial_url_params, 'allergy_ids');
            let allergy_names = getDecodedURIComponents(getArray(initial_url_params, 'allergy_names'));

            //We get "drugs" as an array of values; they need to be parsed into a list of ids for this to work.
            //First, save the existing drugs and allergies in the state.
            let combinedDrugObjs = [];
            for(let i = 0; i < drug_ids.length; i++) {
                let newDrugObj = { id: drug_ids[i], name: drug_names[i] };
                combinedDrugObjs.push(newDrugObj);
            }

            let combinedAllergyObjs = [];
            if(allergy_ids.length>0) {
                for (let i = 0; i < allergy_ids.length; i++) {
                    let newAllergyObj = {id: allergy_ids[i], name: allergy_names[i] };
                    combinedAllergyObjs.push(newAllergyObj);
                }
            }

            let new_interaction_object = { drugs: combinedDrugObjs, allergies: combinedAllergyObjs };

            dispatch(makeChangeInteractionObjectsAction(new_interaction_object));

            //Now we need to update the filters to reflect reality.
            const filterObject = getFilterObjectForDefinedDrugsAndAllergies(drug_ids, allergy_ids);
            const drugNamesFromState = getItemNames(props.current_state, 'drug_interaction_objects.drugs');
            const allergyNamesFromState = getItemNames(props.current_state, 'drug_interaction_objects.allergies');
            if ((!areArraysEqual(drugNamesFromState, drug_names) 
                || !areArraysEqual(allergyNamesFromState, allergy_names) 
                || isNewRequest(props, filterObject))
              || localStorage.getItem(DRUG_INTERACTION_ERROR) === 'true') {
                dispatch(makeApplyNewFilterAction(filterObject));

                //Turn off the filters.
                dispatch(makeShowFilterPanelAction(false));

                //Now run the actual request.
                dispatch(makeDrugInteractionActionThunk(
                    drug_ids,
                    allergy_ids,
                    props
                ));
                dispatch(makeDiShowUnableToDeleteMessageAction(false));
            }
        },
        onUpdateSelectedDrugs: (drugs={}) => {
            dispatch(makeUpdateSelectedDrugsAction(drugs));
        },

        onGoToChatbotPage: (props) => {
            dispatch(makeDrugInteractionGoToChatbotActionThunk(props));
        },
        onRemoveDrugName: (drug_name, props) => {
            console.debug("onRemoveDrugName drug_name = ", drug_name)
            diRemoveItem(dispatch, drug_name, props, true);
        },
        onRemoveAllergyName: (allergy_name, props) => {
            console.debug("onRemoveAllergyName allergy_name = ", allergy_name)
            diRemoveItem(dispatch, allergy_name, props, false);
        },
        onShowUnableToDeleteMessage: () => {
            dispatch(makeDiShowUnableToDeleteMessageAction(true));
        },
        onDismissUnableToDeleteMessage: () => {
            dispatch(makeDiShowUnableToDeleteMessageAction(false));
        }
    }
}


/**
 * This is the top level Drug Interaction component displayed
 */
export class DrugInteractionPagePresentation extends Component {
    initial_url_params = {}
    constructor(props) {
        super(props);
        console.debug('DrugInteractionPagePresentation CTOR props=', props)


        // TODO: move queryString.parse(props.location.search) to a wrapper component
        // Expect the following query parameters from the quick links button
        // that the user clicked:
        // contentSetId
        // drugName
        // documentId
        // topicId
        const loc_search = get(this.props,'location.search')
        this.state = {
          previous_url_params: loc_search
         };
        console.debug('CTOR this.props.location.search=',loc_search)
        if (loc_search) {
            this.initial_url_params = queryString.parse(loc_search)
            console.debug('CTOR initial_url_params=',this.initial_url_params)
        }
        this.scrollingDivRef = createRef()
        this.gotoChatbotPage = this.gotoChatbotPage.bind(this)
        // this.scrollToTopHandler = this.scrollToTopHandler.bind(this);
        this.drugInteractionData = this.drugInteractionData.bind(this);
        this.buildModifySearchURL = this.buildModifySearchURL.bind(this);
    } //endconstructor

    /**
     * Render the DrugInteractionPage
     * @returns {*}
     */

    gotoChatbotPage() {
        this.props.onGoToChatbotPage(this.props);
    }
    // MM : This method is not required as it's moved to common component
    // scrollToTopHandler(event) {
    //     console.debug('Drug - Event========', event);
    //     this.scrollingDivRef.current.scrollTop = 0
    //     console.debug('Drug - this.scrollingDivRef========', this.scrollingDivRef);
    // }
    drugInteractionData(){
        this.props.onDisplayInteraction(this.initial_url_params, this.props);
    }

    /**
     * When the react gui is displayed,
     * send the first drug points page information request
     */
    componentDidMount() {
        // if initial_url_params is not empty, then we are responding
        if (!isEmptyObject(this.initial_url_params)) {
            const saved_url_params = this.initial_url_params
            this.initial_url_params = {}
            this.props.onDisplayInteraction(
                saved_url_params,
                this.props
            )
        }

        // else if pending_render_state is empty, then we are responding to just
        // a page switch from the ChatbotPage

    }

    componentWillUnmount() {
        this.props.onDismissUnableToDeleteMessage();
    }
 
    componentDidUpdate(){
      // if the current url params is different from previous one, then we are responding
      // and changing the previous_url_params
      if(this.props.location.search !== this.state.previous_url_params){
        this.setState({
          previous_url_params: this.props.location.search
        });
        const saved_url_params = queryString.parse(this.props.location.search)
        this.props.onDisplayInteraction(
            saved_url_params,
            this.props
        )
      }
    }

    render() {
        const sectionName = 'submittedSearch';
        let current_state = this.props.current_state;
        const {
            filter_modal_visible=false
        } = current_state

        const diFilterModalPopup = filter_modal_visible ? <DrugInteractionFilterModalParent/> : <Fragment/>
        let linkUrl = this.buildModifySearchURL();
        const linkURL_encoded =encodeURI(linkUrl);
        const drugNames = getItemNames(current_state, 'drug_interaction_objects.drugs');
        const allergyNames = getItemNames(current_state, 'drug_interaction_objects.allergies');
        const only_one_drug = (drugNames.length === 1);
        return <div className="main-container">
                <div  className="empty-container"></div>
                <div className="main-content">
                    <div ref={this.scrollingDivRef} className="mdx-scroll-area mdx-scroll-area-ie">
                        <div className="mdx-header">
                            <Link className='drug-interaction-modify-link' key='a'  to={linkURL_encoded}>
                                <ChevronLeft className="chevronleft16_fill" />
                                <span>Modify Search</span>
                            </Link>
                            <h1 className="drug-interaction-title-1 drug-interaction-page-titles">Drug Interactions <span>Results Summary</span></h1>
                        </div>
                      <div className="di-submitted-search">
                        <div className="search-title">
                          <h5>Submitted Search</h5>
                          <div className="right_header">
                            <DrugInteractionsFilterPill
                              invokedSectionName={sectionName}
                              shouldIncludeDrugsAndAllergies = {true}
                              disabled = {this.props.diSummaryPageResponseLoading.status}/>

                            <DrugInteractionFilterComponent
                              invokedSectionName = {sectionName}
                              disabled = {this.props.diSummaryPageResponseLoading.status}
                              shouldRenderOnlySeverities = {false}
                              history = {this.props.history}
                            />
                          </div>
                        </div>
                        {
                          this.props.show_unable_to_delete
                          &&
                          <ToastNotification
                            className="di-submitted-search-cannot-delete-toast"
                            lowContrast={false}
                            title={"Cannot Delete!"}
                            caption=''
                            onCloseButtonClick={this.props.onDismissUnableToDeleteMessage}
                            subtitle={"Unable to delete the last drug. Please use the Modify Search link to make drug and allergy changes."}
                            kind={"error"}
                          />
                        }
                        <div className="di-compat-submitted-search-box">
                          <div className="di-compat-selected-item-container">
                            <span className="di-compat-selected-item-title">Drug(s):&nbsp;</span>
                            {
                              drugNames.map((drug_name, index) => {
                                  return <SelectedItem
                                    long_name = {drug_name}
                                    key = {index}
                                    click_handler = {
                                      only_one_drug
                                        ?
                                        () => this.props.onShowUnableToDeleteMessage()
                                        :
                                        () => this.props.onRemoveDrugName(drug_name, this.props)
                                    }
                                    waiting_flag = {this.props.diSummaryPageResponseLoading.status}
                                  />
                                }
                              )}
                          </div>
                          {
                            allergyNames.length !== 0 &&
                            <div className="di-compat-selected-item-container">
                              <span className="di-compat-selected-item-title">Allergies:&nbsp;</span>
                              {
                                allergyNames.map((allergy_name, index) => {
                                    return  <SelectedItem
                                      long_name = {allergy_name}
                                      key = {index}
                                      click_handler = {
                                        () => this.props.onRemoveAllergyName(allergy_name, this.props)
                                      }
                                      waiting_flag = {this.props.diSummaryPageResponseLoading.status}
                                    />
                                  }
                                )
                              }
                            </div>
                          }
                        </div>
                      </div>
                        <div className="mdx-inner-content">
                            <DrugInteractionSelectionList/>
                        </div>
                      <CommonFooter/>
                    </div>
                </div>
            {diFilterModalPopup}
            <CommonOverflowModalPopups/>
        </div>
    }

    buildModifySearchURL() {
        let url = '/drugInteractionSearch?';
        let drugs = get(this.props.interaction_objects, 'drugs', []);
        let allergies = get(this.props.interaction_objects, 'allergies', []);
        for (let i=0;i< drugs.length;i++)
        {
            url+="&drug_ids=" + drugs[i].id;
            url+="&drug_names=" + encodeURIComponent(drugs[i].name);
        }

        for (let i=0;i< allergies.length;i++)
        {
            url+="&allergy_ids=" + allergies[i].id;
            url+="&allergy_names=" + encodeURIComponent(allergies[i].name);
        }

        return url;
    }
}

const isNewRequest = (props, filterObject) => {
    let oldDrugs = [];
    let oldAllergies = [];
    const currentState = props.current_state.drug_interaction_objects;
    currentState.drugs.forEach(drug => oldDrugs.push(drug.id));
    currentState.allergies.forEach(allergy => oldAllergies.push(allergy.id));
    if (oldDrugs.length !== filterObject.drugs.length
        || oldAllergies.length !== filterObject.allergies.length) {
        return true;
    }
    return !oldDrugs.every((e, i) => e === filterObject.drugs[i])
        || !oldAllergies.every((e, i) => e === filterObject.allergies[i]);
}

const DrugInteractionPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(DrugInteractionPagePresentation)

export default DrugInteractionPage
